import { Page } from "@react-pdf/renderer";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { types } from "@vilocnv/allsetra-core";
import {
  getAllAccountsThunk,
  getAccountsByQueryThunk,
  getAccountsIndustriesThunk,
  getAccountsTypesThunk,
  getAccountAssociatedUsersThunk,
  getAccountAssociatedAvailableUsersThunk,
  getAccountServicesThunk,
  getAccountDeviceTypesThunk,
  getAccountObjectTypesThunk,
  getAccountObjectsThunk,
  getAccountDevicesThunk,
  getAccountInstallationsThunk,
  getSpecificAccountThunk,
  getAccountSubscriptionsBySearchThunk,
  getDeviceSubscriptionsConnectedtoObjectAndAccountThunk,
  getUsersWithRoleDriverThunk,
  searchAccountAlarmsThunk,
  getAccountKeysByQueryThunk,
  getAccountUsersByQueryThunk,
  searchAccountsByQueryThunk,
  getUsersBasicInfoThunk,
  getAccountAllServicesThunk,
  getAvailableKeysByAccountThunk,
  getAllKeysByAccountThunk,
  searchMinimalAccountsThunk,
} from "./actions";
import { getAllAccountGroupsThunk } from "./actions/accountGroupsActions";

export interface IAccountsState {
  loading: boolean;
  usersLoading: boolean;
  accountsLoading: boolean;
  searchedUsers: Array<any>;
  searchedAccounts: Array<any>;

  availableUsersLoading: boolean;
  availableSearchedUsers: Array<any>;

  // Account Alarm State
  accountAlarmsCount: number | null;
  accountAlarms: Array<any>;
  accountAlarmsLoading: boolean;

  // Accounts State
  totalAccounts: number | null;
  activeAccountId: string | null;
  activeAccount: types.IAccount | null;
  allAccounts: Array<any>;

  accountsIndustriesLoading: boolean;
  accountsIndustries: Array<any>;
  accountsTypesLoading: boolean;
  accountsTypes: Array<any>;

  // Account Details State
  activeTabIndex: number;
  totalRecords: number | null;
  accountUsers: Array<any>;
  accountUsersLoading: boolean;
  accountServices: Array<any>;
  accountServicesLoading: boolean;
  accountDeviceTypes: Array<any>;
  accountDeviceTypesLoading: boolean;
  accountObjectTypes: Array<any>;
  accountObjectTypesLoading: boolean;
  accountObjects: Array<any>;
  accountObjectsLoading: boolean;
  deviceLoading: boolean;
  accountDevices: Array<any>;
  accountInstallations: Array<any>;
  accountInstallationsLoading: boolean;
  accountGroups: Array<any>;
  accountGroupsLoading: boolean;
  accountSubscriptions: Array<any>;
  accountSubscriptionsLoading: boolean;
  accountDeviceSubscriptions: Array<any>;
  usersWithRoleDriver: Array<any>;
  usersWithRoleDriverLoading: boolean;
  usersBasicInfo: Array<any>;
  minimalSearchedAccounts: Array<any>;

  accountKeys: Array<any>;
  accountKeysLoading: boolean;
  accountAvailableKeys: Array<any>;
  accountAvailableKeysLoading: boolean;
}

const initialState: IAccountsState = {
  loading: true,
  usersLoading: false,
  accountsLoading: false,
  searchedUsers: [],
  searchedAccounts: [],

  availableUsersLoading: false,
  availableSearchedUsers: [],

  totalAccounts: null,
  activeAccountId: null,
  activeAccount: null,
  allAccounts: [],

  accountsIndustriesLoading: false,
  accountsIndustries: [],
  accountsTypesLoading: false,
  accountsTypes: [],

  activeTabIndex: 0,
  totalRecords: null,

  // Users
  accountUsers: [],
  accountUsersLoading: false,
  usersWithRoleDriver: [],
  usersWithRoleDriverLoading: false,
  usersBasicInfo: [],
  minimalSearchedAccounts: [],

  // Services
  accountServices: [],
  accountServicesLoading: false,

  // Device Types
  accountDeviceTypes: [],
  accountDeviceTypesLoading: false,

  // Object Types
  accountObjectTypes: [],
  accountObjectTypesLoading: false,

  // Objects
  accountObjects: [],
  accountObjectsLoading: false,

  // Devices
  accountDevices: [],
  deviceLoading: false,

  // Installation
  accountInstallations: [],
  accountInstallationsLoading: false,

  // Groups
  accountGroups: [],
  accountGroupsLoading: false,

  // Subscriptions
  accountSubscriptions: [],
  accountSubscriptionsLoading: false,
  accountDeviceSubscriptions: [],

  // Alarms
  accountAlarmsCount: 0,
  accountAlarms: [],
  accountAlarmsLoading: false,

  // Keys
  accountKeys: [],
  accountKeysLoading: false,
  accountAvailableKeys: [],
  accountAvailableKeysLoading: false,
};

const accountsSlice = createSlice({
  name: "accounts",
  initialState,
  reducers: {
    setActiveAccountId: (state, action: PayloadAction<string | null>) => {
      state.activeAccountId = action.payload;
    },
    setActiveTabIndex: (state, action: PayloadAction<number>) => {
      state.activeTabIndex = action.payload;
    },
    resetActiveAccountState: (state) => {
      state.activeAccountId = null;
      state.activeAccount = null;
      state.activeTabIndex = 0;
    },
    resetAccountObjects: (state) => {
      state.accountObjects = [];
    },
  },
  extraReducers: (builder) => {
    // Get All Accounts Action Cases
    builder.addCase(getAllAccountsThunk.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getAllAccountsThunk.fulfilled, (state, action) => {
      state.allAccounts = action.payload;
      state.loading = false;
    });

    builder.addCase(getAllAccountsThunk.rejected, (state) => {
      state.loading = false;
    });

    // Get Accounts By Querying Action Cases
    builder.addCase(getAccountsByQueryThunk.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getAccountsByQueryThunk.fulfilled, (state, action) => {
      state.allAccounts = action.payload?.results || [];
      state.totalAccounts = action.payload?.rowCount || 0;
      state.loading = false;
    });

    builder.addCase(getAccountsByQueryThunk.rejected, (state) => {
      state.loading = false;
    });

    // Get Accounts By Search Action Cases
    builder.addCase(searchAccountsByQueryThunk.pending, (state) => {
      state.accountsLoading = true;
    });

    builder.addCase(searchAccountsByQueryThunk.fulfilled, (state, action) => {
      state.searchedAccounts = action.payload?.results || [];
      state.accountsLoading = false;
    });

    builder.addCase(searchAccountsByQueryThunk.rejected, (state) => {
      state.accountsLoading = false;
    });

    // Get Specific Account Action Cases
    builder.addCase(getSpecificAccountThunk.pending, (state) => {
      state.activeAccount = null;
      state.loading = true;
    });

    builder.addCase(getSpecificAccountThunk.fulfilled, (state, action) => {
      state.activeAccount = action.payload;
      state.loading = false;
    });

    builder.addCase(getSpecificAccountThunk.rejected, (state) => {
      state.loading = false;
    });

    // Get Accounts Industries Thunk Action Cases
    builder.addCase(getAccountsIndustriesThunk.pending, (state) => {
      state.accountsIndustriesLoading = true;
    });

    builder.addCase(getAccountsIndustriesThunk.fulfilled, (state, action) => {
      state.accountsIndustries = action.payload;
      state.accountsIndustriesLoading = false;
    });

    builder.addCase(getAccountsIndustriesThunk.rejected, (state) => {
      state.accountsIndustriesLoading = false;
    });

    // Get Accounts Types Thunk Action Cases
    builder.addCase(getAccountsTypesThunk.pending, (state) => {
      state.accountsTypesLoading = true;
    });

    builder.addCase(getAccountsTypesThunk.fulfilled, (state, action) => {
      state.accountsTypes = action.payload;
      state.accountsTypesLoading = false;
    });

    builder.addCase(getAccountsTypesThunk.rejected, (state) => {
      state.accountsTypesLoading = false;
    });

    //Get users query cases
    builder.addCase(getAccountUsersByQueryThunk.pending, (state) => {
      state.usersLoading = true;
    });

    builder.addCase(getAccountUsersByQueryThunk.fulfilled, (state, action) => {
      state.searchedUsers =
        [...state.searchedUsers, ...action.payload.results] || [];
      state.usersLoading = false;
    });

    builder.addCase(getAccountUsersByQueryThunk.rejected, (state) => {
      state.usersLoading = false;
      state.searchedUsers = [];
    });

    // Get Account Users Action Cases
    builder.addCase(getAccountAssociatedUsersThunk.pending, (state) => {
      state.accountUsersLoading = true;
    });

    builder.addCase(
      getAccountAssociatedUsersThunk.fulfilled,
      (state, action) => {
        state.accountUsers = action.payload?.results || [];
        state.totalRecords = action.payload?.rowCount || 0;
        state.accountUsersLoading = false;
      }
    );

    builder.addCase(getAccountAssociatedUsersThunk.rejected, (state) => {
      state.accountUsersLoading = false;
    });

    // Get Account Available Users Action Cases
    builder.addCase(
      getAccountAssociatedAvailableUsersThunk.pending,
      (state) => {
        state.availableUsersLoading = true;
      }
    );

    builder.addCase(
      getAccountAssociatedAvailableUsersThunk.fulfilled,
      (state, action) => {
        state.availableSearchedUsers = action.payload?.results || [];
        state.availableUsersLoading = false;
      }
    );

    builder.addCase(
      getAccountAssociatedAvailableUsersThunk.rejected,
      (state) => {
        state.availableUsersLoading = false;
        state.availableSearchedUsers = [];
      }
    );

    // Get Account All Services Action Cases
    builder.addCase(getAccountAllServicesThunk.pending, (state) => {
      state.accountServicesLoading = true;
    });

    builder.addCase(getAccountAllServicesThunk.fulfilled, (state, action) => {
      state.accountServices = action.payload || [];
      state.accountServicesLoading = false;
    });

    builder.addCase(getAccountAllServicesThunk.rejected, (state) => {
      state.accountServicesLoading = false;
    });

    // Get Account Services Action Cases
    builder.addCase(getAccountServicesThunk.pending, (state) => {
      state.accountServicesLoading = true;
    });

    builder.addCase(getAccountServicesThunk.fulfilled, (state, action) => {
      state.accountServices = action.payload?.results || [];
      state.totalRecords = action.payload?.rowCount || 0;
      state.accountServicesLoading = false;
    });

    builder.addCase(getAccountServicesThunk.rejected, (state) => {
      state.accountServicesLoading = false;
    });

    // Get Account Device Types Action Cases
    builder.addCase(getAccountDeviceTypesThunk.pending, (state) => {
      state.accountDeviceTypesLoading = true;
    });

    builder.addCase(getAccountDeviceTypesThunk.fulfilled, (state, action) => {
      state.accountDeviceTypes = action.payload?.results || [];
      state.totalRecords = action.payload?.rowCount || 0;
      state.accountDeviceTypesLoading = false;
    });

    builder.addCase(getAccountDeviceTypesThunk.rejected, (state) => {
      state.accountDeviceTypesLoading = false;
      state.accountDeviceTypes = [];
      state.totalRecords = 0;
    });

    // Get Account Object Types Action Cases
    builder.addCase(getAccountObjectTypesThunk.pending, (state) => {
      state.accountObjectTypesLoading = true;
    });

    builder.addCase(getAccountObjectTypesThunk.fulfilled, (state, action) => {
      state.accountObjectTypes = action.payload?.results || [];
      state.totalRecords = action.payload?.rowCount || 0;
      state.accountObjectTypesLoading = false;
    });

    builder.addCase(getAccountObjectTypesThunk.rejected, (state) => {
      state.accountObjectTypesLoading = false;
      state.accountObjectTypes = [];
      state.totalRecords = 0;
    });

    // Get Account Objecs Action Cases
    builder.addCase(getAccountObjectsThunk.pending, (state) => {
      state.accountObjectsLoading = true;
    });

    builder.addCase(getAccountObjectsThunk.fulfilled, (state, action) => {
      state.accountObjects = action.payload?.results || [];
      state.totalRecords = action.payload?.rowCount || 0;
      state.accountObjectsLoading = false;
    });

    builder.addCase(getAccountObjectsThunk.rejected, (state) => {
      state.accountObjectsLoading = false;
      state.accountObjects = [];
      state.totalRecords = 0;
    });

    // Get Account Devices Action Cases
    builder.addCase(getAccountDevicesThunk.pending, (state) => {
      state.deviceLoading = true;
    });

    builder.addCase(getAccountDevicesThunk.fulfilled, (state, action) => {
      state.accountDevices = action.payload?.results;
      state.totalRecords = action.payload?.rowCount;
      state.deviceLoading = false;
    });

    builder.addCase(getAccountDevicesThunk.rejected, (state) => {
      state.accountDevices = [];
      state.totalRecords = 0;
      state.deviceLoading = false;
    });

    // Get Account Installations Action Cases
    builder.addCase(getAccountInstallationsThunk.pending, (state) => {
      state.accountInstallationsLoading = true;
    });

    builder.addCase(getAccountInstallationsThunk.fulfilled, (state, action) => {
      state.accountInstallations = action.payload?.results || [];
      state.totalRecords = action.payload?.rowCount || 0;
      state.accountInstallationsLoading = false;
    });

    builder.addCase(getAccountInstallationsThunk.rejected, (state) => {
      state.accountInstallationsLoading = false;
      state.accountInstallations = [];
      state.totalRecords = 0;
    });

    // Get All Account Groups Action Cases
    builder.addCase(getAllAccountGroupsThunk.pending, (state) => {
      state.accountGroupsLoading = true;
    });

    builder.addCase(getAllAccountGroupsThunk.fulfilled, (state, action) => {
      state.accountGroups = action.payload || [];
      state.totalRecords = action.payload?.rowCount || 0;
      state.accountGroupsLoading = false;
    });

    builder.addCase(getAllAccountGroupsThunk.rejected, (state) => {
      state.accountGroups = [];
      state.totalRecords = 0;
      state.accountGroupsLoading = false;
    });

    // Get Account Subscriptions Action Cases
    builder.addCase(getAccountSubscriptionsBySearchThunk.pending, (state) => {
      state.accountSubscriptionsLoading = true;
    });

    builder.addCase(
      getAccountSubscriptionsBySearchThunk.fulfilled,
      (state, action) => {
        state.accountSubscriptions = action.payload?.results || [];
        state.totalRecords = action.payload?.rowCount || 0;
        state.accountSubscriptionsLoading = false;
      }
    );

    builder.addCase(getAccountSubscriptionsBySearchThunk.rejected, (state) => {
      state.accountSubscriptionsLoading = false;
    });

    // Get Account Device Subscriptions Action Cases
    builder.addCase(
      getDeviceSubscriptionsConnectedtoObjectAndAccountThunk.pending,
      (state) => {
        state.loading = true;
      }
    );

    builder.addCase(
      getDeviceSubscriptionsConnectedtoObjectAndAccountThunk.fulfilled,
      (state, action) => {
        state.accountDeviceSubscriptions = action.payload?.results || [];
        state.totalRecords = action.payload?.rowCount || 0;
        state.loading = false;
      }
    );

    builder.addCase(
      getDeviceSubscriptionsConnectedtoObjectAndAccountThunk.rejected,
      (state) => {
        state.loading = false;
      }
    );

    // Get Account Users With Role Driver
    builder.addCase(getUsersWithRoleDriverThunk.pending, (state) => {
      state.usersWithRoleDriverLoading = true;
    });

    builder.addCase(getUsersWithRoleDriverThunk.fulfilled, (state, action) => {
      state.usersWithRoleDriver = action.payload;
      state.usersWithRoleDriverLoading = false;
    });

    builder.addCase(getUsersWithRoleDriverThunk.rejected, (state) => {
      state.usersWithRoleDriverLoading = false;
    });

    // Search All Account Alarms
    builder.addCase(searchAccountAlarmsThunk.pending, (state) => {
      state.accountAlarmsLoading = true;
    });

    builder.addCase(searchAccountAlarmsThunk.fulfilled, (state, action) => {
      state.accountAlarms = action.payload?.results || [];
      state.accountAlarmsCount = action.payload?.rowCount || 0;
      state.accountAlarmsLoading = false;
    });

    builder.addCase(searchAccountAlarmsThunk.rejected, (state) => {
      state.accountAlarms = [];
      state.accountAlarmsCount = 0;
      state.accountAlarmsLoading = false;
    });

    // Get All Account Keys Action Cases
    builder.addCase(getAccountKeysByQueryThunk.pending, (state) => {
      state.accountKeysLoading = true;
    });

    builder.addCase(getAccountKeysByQueryThunk.fulfilled, (state, action) => {
      state.accountKeys = action.payload?.results || [];
      state.totalRecords = action.payload?.rowCount || 0;
      state.accountKeysLoading = false;
    });

    builder.addCase(getAccountKeysByQueryThunk.rejected, (state) => {
      state.accountKeys = [];
      state.totalRecords = 0;
      state.accountKeysLoading = false;
    });

    // Get All Account Keys Action Cases
    builder.addCase(getAllKeysByAccountThunk.pending, (state) => {
      state.accountKeysLoading = true;
    });

    builder.addCase(getAllKeysByAccountThunk.fulfilled, (state, action) => {
      state.accountKeys = action.payload;
      state.accountKeysLoading = false;
    });

    builder.addCase(getAllKeysByAccountThunk.rejected, (state) => {
      state.accountKeysLoading = false;
    });

    // Get Available Account Keys Action Cases
    builder.addCase(getAvailableKeysByAccountThunk.pending, (state) => {
      state.accountAvailableKeysLoading = true;
    });

    builder.addCase(
      getAvailableKeysByAccountThunk.fulfilled,
      (state, action) => {
        state.accountAvailableKeys = action.payload || [];
        state.accountAvailableKeysLoading = false;
      }
    );

    builder.addCase(getAvailableKeysByAccountThunk.rejected, (state) => {
      state.accountAvailableKeysLoading = false;
    });

    // getUsersBasicInfoThunk
    builder.addCase(getUsersBasicInfoThunk.fulfilled, (state, action) => {
      state.usersBasicInfo = action.payload || [];
    });

    // Minimal Searched Accounts
    // builder.addCase(searchMinimalAccountsThunk.pending, (state) => {
    //   state.minimalSearchedAccounts = false;
    // });

    // searchMinimalAccountsThunk
    builder.addCase(searchMinimalAccountsThunk.fulfilled, (state, action) => {
      const existingIds = new Set();
      const newUniqueAccounts = [];
      const seenIds = new Set();

      // Populate existing IDs (avoids recreating array with map)
      for (const account of state?.minimalSearchedAccounts) {
        existingIds.add(account?.uniqueId);
      }

      // Filter new results in single pass
      for (const account of action.payload.results) {
        const uid = account.uniqueId;
        if (!existingIds.has(uid) && !seenIds.has(uid)) {
          seenIds.add(uid);
          newUniqueAccounts.push(account);
        }
      }

      // Avoid spread operator for large arrays
      if (newUniqueAccounts.length > 0) {
        state.minimalSearchedAccounts =
          state?.minimalSearchedAccounts.concat(newUniqueAccounts);
      }
    });

    // getUsersBasicInfoThunk
    // builder.addCase(searchMinimalAccountsThunk.rejected, (state, action) => {
    //   state.minimalSearchedAccounts = action.payload || [];
    // });
  },
});

export * from "./actions";
export * from "./accountsQueries";
export const {
  setActiveAccountId,
  setActiveTabIndex,
  resetActiveAccountState,
  resetAccountObjects,
} = accountsSlice.actions;

export default accountsSlice.reducer;
