import { LinkArrowIcon } from "assets/icons";
import { FC, ReactNode } from "react";
import { ContentBox } from "./GoogleMapLinkTag.styled";

interface GoogleMapLinkTagProps {
  children: ReactNode;
  latitude: number;
  longitude: number;
  target?: string;
  style?: {
    color?: string;
    textDecoration?: string;
  };
}

const GoogleMapLinkTag: FC<GoogleMapLinkTagProps> = ({
  children,
  latitude,
  longitude,
  target = "_blank",
  style = {
    color: "purple",
    textDecoration: "underline",
  },
}) => {
  return (
    <a
      href={`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`}
      target={target}
      rel="noopener noreferrer"
      style={style}
    >
      <ContentBox>
        {children}
        <LinkArrowIcon className="arrow-icon" />
      </ContentBox>
    </a>
  );
};

export default GoogleMapLinkTag;
