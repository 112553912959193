import { FC } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { Box, useTheme } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {
  Table,
  Topbar,
  types,
  useDispatchOnParams,
} from "@vilocnv/allsetra-core";

// Data

import { useAppDispatch, useAppSelector } from "hooks";
import { whiteLabelsTableColumns } from "app/data/constants";
import { selectWhiteLabelsState } from "app/data/selectors";
import {
  getWhiteLabelsByQueryThunk,
  resetSpecificWhiteLabel,
} from "app/features";
import { useTranslation } from "react-i18next";

const WhiteLabelManager: FC = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Global State
  const { loading, totalRecords, whiteLabels } = useAppSelector(
    selectWhiteLabelsState
  );

  useDispatchOnParams(getWhiteLabelsByQueryThunk, {
    searchByField: "account.name",
  });

  const handleAddWhiteLabel = () => {
    dispatch(resetSpecificWhiteLabel());
    navigate("/dashboard/whitelabel-manager/configuration");
  };

  const handleRowClicked = (row: types.IWhiteLabel) => {
    navigate({
      pathname: "/dashboard/whitelabel-manager/configuration",
      search: createSearchParams({
        id: row.uniqueId,
      }).toString(),
    });
  };

  return (
    <Box>
      <Topbar
        theme={theme}
        title={t("drawerMenuLinks.whiteLabelManager")}
        primaryButton={{
          id: "add",
          variant: "outlined",
          text: "Add white label",
          startIcon: <AddIcon />,
          onClick: handleAddWhiteLabel,
        }}
      />
      <Box mx={4}>
        <Table
          columns={whiteLabelsTableColumns}
          data={whiteLabels ?? []}
          progressPending={loading}
          paginationTotalRows={totalRecords}
          searchPlaceholder="Search white label"
          onRowClicked={handleRowClicked}
        />
      </Box>
    </Box>
  );
};

export default WhiteLabelManager;
