import React, { FC, Fragment, useCallback, useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";
import {
  UnauthenticatedTemplate,
  AuthenticatedTemplate,
  useMsal,
  useIsAuthenticated,
} from "@azure/msal-react";
import { DashboardLayout, PageLoader, types } from "@vilocnv/allsetra-core";
import * as Sentry from "@sentry/react";

// DATA
import { useTranslation } from "react-i18next";
import {
  useAppDispatch,
  useAppSelector,
  useSetLangOnSettingsChange,
} from "hooks";
import {
  searchMinimalAccountsThunk,
  setDrawerCollapseState,
} from "app/features";
import { selectIsDrawerCollapsed } from "app/data/selectors";
import { getDrawerMenuItems, getDrawerSubMenuLists } from "app/data/constants";
import { SignalRService } from "app/data/services";
import { signalRConnectionEvent } from "app/data/services/SignalRService";
import {
  signalRAlarmsEventsListener,
  signalREventsRaisedListener,
} from "app/data/helpers/signalRHelpers";
import { handleAlarmNotification } from "app/data/services/NotificationService";

const ALLSETRA_ACCOUNT_ID = "1602fe1b-9094-497b-afa6-0befed507214";

export interface ProtectedRouteProps {
  redirectTo: string;
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({ redirectTo }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname, search } = useLocation();
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const isDrawerCollapsed = useAppSelector(selectIsDrawerCollapsed);

  // Local
  const [isOnline, setIsOnline] = useState<boolean>(false); // Saves the state for network
  const [renderCycle, setRenderCycle] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useSetLangOnSettingsChange();

  useEffect(() => {
    setIsOnline(window.navigator.onLine);

    const setToOnline = () => setIsOnline(true);

    const setToOffline = () => setIsOnline(false);

    window.addEventListener("online", setToOnline);
    window.addEventListener("offline", setToOffline);

    dispatch(
      searchMinimalAccountsThunk({
        itemsPerPage: 10,
        page: 1,
        where: [
          {
            field: "uniqueId",
            value: ALLSETRA_ACCOUNT_ID,
            type: 0,
          },
        ],
      })
    );

    return () => {
      window.removeEventListener("online", setToOnline);
      window.removeEventListener("offline", setToOffline);
    };
  }, []);

  const { t } = useTranslation();

  const toggleDrawerCollapseState = () => {
    dispatch(setDrawerCollapseState(!isDrawerCollapsed));
  };

  const handleLogout = useCallback(async () => {
    try {
      await instance.logoutRedirect({ postLogoutRedirectUri: "/" });
      Sentry.setUser(null);
      window.localStorage.clear();
    } catch (error) {
      console.error("Error during logout process:", error);
    }
  }, [instance]);

  const { drawerMenuItems, drawerSubMenuLists } = {
    drawerMenuItems: getDrawerMenuItems(t),
    drawerSubMenuLists: getDrawerSubMenuLists(t),
  };

  const fullPath = `${pathname}${search}`;

  window.onbeforeunload = () => {
    sessionStorage.setItem("oldPathname", fullPath);
  };

  useEffect(() => {
    if (pathname?.split("/dashboard")[1]) {
      sessionStorage.setItem("pathname", fullPath);
    }
  }, [pathname]);

  useEffect(() => {
    const fullPath = `${pathname}${search}`;
    const redirectPath = sessionStorage.getItem("redirect-path");

    if (!isAuthenticated) {
      sessionStorage.setItem("redirect-path", fullPath);
    } else {
      navigate(redirectPath as string);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    signalRConnectionEvent.subscribe((value) => {
      if (value === "Connected") {
        setRenderCycle((cycle) => cycle + 1);
      }

      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if ("serviceWorker" in Notification) {
      navigator.serviceWorker
        .register("/sw.js")
        .then((registration) => {
          console.log(
            "Service Worker registered with scope:",
            registration.scope
          );
        })
        .catch((error) => {
          console.error("Service Worker registration failed:", error);
        });
    }
  }, []);

  useEffect(() => {
    if (SignalRService.hubConnection?.state === "Connected") {
      SignalRService.hubConnection.on("EventRaised", (event) => {
        // Handle alarm notifications for all pages
        if (event.eventName === types.BackendEventsEnum.AlarmCreatedEvent) {
          handleAlarmNotification(event);
        }

        // Handle other alarm-related events
        signalRAlarmsEventsListener(event, dispatch);
        signalREventsRaisedListener(event, dispatch);
      });

      return () => {
        SignalRService.hubConnection?.off("EventRaised");
      };
    }
  }, [dispatch, SignalRService.hubConnection?.state]);

  return (
    <Fragment>
      <UnauthenticatedTemplate>
        <Navigate to={redirectTo} state={{ from: pathname }} />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate key={renderCycle}>
        {isLoading ? (
          <PageLoader sx={{ width: "100dvw" }} />
        ) : (
          <DashboardLayout
            // @ts-ignore
            size={"large"}
            isDrawerCollapsed={isDrawerCollapsed}
            toggleDrawerCollapseState={toggleDrawerCollapseState}
            appLogoType={"admin"}
            menuList={drawerMenuItems}
            subLists={drawerSubMenuLists}
            activeLinkTextColor={theme.palette.primary.light}
            activeLinkBgColor={theme.palette.primary.dark}
            showSignalRBadge={true}
            isSignalRConnected={
              SignalRService.hubConnection?.state === "Connected" && isOnline
            }
            onSupportClick={() => {}}
            onLogoutClick={handleLogout}
          />
        )}
      </AuthenticatedTemplate>
    </Fragment>
  );
};

export default ProtectedRoute;
