import * as Yup from "yup";
import { isEmpty, omit } from "lodash";
import {
  IAccountAssignUser,
  IAccountAssignService,
  IAccountAssignDeviceType,
  IAccountAssignObjectType,
} from "../types";
import { transformAddressObjectForForm } from "./commonHelpers";
import { helpers } from "@vilocnv/allsetra-core";

//
// Account Details Helpers
//
export const accountDetailsFormatterForForm = (account: any) => {
  if (isEmpty(account)) return {};

  const removedUnwantedKeys = omit(account, [
    "created",
    "createdBy",
    "deleted",
    "deletedBy",
    "isDeleted",
    "lastUpdated",
    "status",
    "updatedBy",
    "owner",
    "devices",
    "usersCount",
    "objectsCount",
    "linkedObjects",
    "countries",
    "accountOwner",
    "resellerReference",
    "referenceKey",
    "phoneNumber",
    "description",
    "debtorNumber",
    "customerType",
  ]);

  const formattedObject = {
    ...removedUnwantedKeys,
    accountIndustry: account.accountIndustry?.id,
    accountType: account.accountType?.id,
    accountOwnerUniqueId: account.accountOwner,
    workingHours: {
      workingHoursSchedule: account.workingHours,
    },
    billingAddress: transformAddressObjectForForm(account.billingAddress),
    shippingAddress: transformAddressObjectForForm(account.shippingAddress),
    visitingAddress: transformAddressObjectForForm(account.visitingAddress),
  };

  return formattedObject;
};

export const accountDetailsValidationSchema = Yup.object().shape({
  uniqueId: Yup.string(),

  // General information section fields
  accountNumber: Yup.string().nullable().label("Account number"),
  name: Yup.string().required().label("Name"),
  accountType: Yup.string().nullable().label("Account Type"),
  accountIndustry: Yup.string().nullable().label("Industry"),
  parentId: Yup.string().nullable().label("Parent account"),
  kvkcocNumber: Yup.string().nullable().label("KVK number"),
  boboid: Yup.string().nullable().label("BOBO ID"),
  multiViewerId: Yup.string().nullable().label("Multi-viewer ID"),
  visitingAddress: helpers.addressValidationSchema,
  website: Yup.string().nullable().label("Website"),
  notes: Yup.string().nullable().label("Notes"),
  workingHoursType: Yup.string().label("Working Hours Type").optional(),
  workingHours: Yup.object({
    workingHoursSchedule: helpers.workingHoursValidationSchema,
  }).optional(),

  // Financial information section fields
  afasDebitNumber: Yup.string().nullable().label("AFAS Debit number"),
  billingAddress: helpers.addressValidationSchema,
  paymentMethod: Yup.string().nullable().label("Payment method"),
  paymentTermInDays: Yup.string().nullable().label("Payment term"),
  vatNumber: Yup.string().nullable().label("VAT number"),
  vatShifted: Yup.string().nullable().label("VAT shifted"),
  bankAccount: Yup.string().nullable().label("AFAS Debit number"),

  // Logistics section fields
  shippingAddress: helpers.addressValidationSchema,

  // Alarm configuration
  alarmsConfiguration: Yup.object().nullable().optional(),
});

//
// Account Notifications Helpers
//
export const accountNotificationsValidationSchema = Yup.object().shape({
  isYearMileageNotificationEnabled: Yup.boolean()
    .nullable()
    .label("Receive notifications about Private drives"),
  yearMileageThreshold: Yup.number()
    .nullable()
    .transform((value, originalValue) => {
      if (
        originalValue === undefined ||
        originalValue === null ||
        originalValue === ""
      ) {
        return null;
      }
      const numberValue = Number(originalValue);
      return isNaN(numberValue) ? null : numberValue;
    })
    .label("Set a mileage threshold per year (km)"),
});

//
// Account Users Helpers
//
export const accountAssignUserInitialValues: IAccountAssignUser = {
  userEmail: "",
  role: "",
  isAlarmContact: false,
  priority: 0,
  linkedToAllObjects: true,
  linkedObjects: [],
};

export const accountAssignUserValidationSchema: Yup.Schema = Yup.object({
  userEmail: Yup.string().email().trim().required().label("User email"),
  role: Yup.string().trim().required().label("Role"),
  key: Yup.string()
    .trim()
    .label("Key")
    .when("role", {
      is: (val: string) => val === "9efb5cd2-ffc2-4792-9d02-dd33dc6d4c3e",
      then: (schema) => schema.required(),
      otherwise: (schema) => schema,
    }),
  isAlarmContact: Yup.boolean().nullable().label("Alarm Contact"),
  priority: Yup.number().nullable().label("Call Alarm Priority"),
  linkedToAllObjects: Yup.boolean().label("Linked To All Objects").required(),
  linkedObjects: Yup.array().of(Yup.string()).label("Linked Objects"),
});

//
// Account Serivces Helpers
//
export const accountAssignServiceInitialValues: IAccountAssignService = {
  serviceId: "",
  subscriptions: [],
  subscriptionsPricing: {},
  features: {},
  deviceTypeProfiles: {},
};

export const accountAssignServiceValidationSchema: Yup.Schema = Yup.object({
  serviceId: Yup.string().trim().required().label("Service"),
  subscriptions: Yup.array()
    .of(Yup.string())
    .min(1)
    .required()
    .label("Subscriptions"),
});

export const transformAccountServiceDataForForm = (service: any) => {
  const subscriptionsPricing: any = {};
  const features: any = {};
  const deviceTypeProfiles: any = {};

  const subscriptions = service.subscriptions?.map((subscription: any) => {
    subscriptionsPricing[subscription.uniqueId] = {
      currency: subscription.currency,
      subscriptionPrice: Number.parseFloat(subscription?.valuePerMonth).toFixed(
        2
      ),
    };

    subscription?.features &&
      subscription?.features.map((item: any) => {
        features[subscription.uniqueId] = {
          ...features[subscription.uniqueId],
          [item?.feature?.uniqueId]: true,
        };
      });

    subscription?.deviceTypeProfiles &&
      subscription?.deviceTypeProfiles.map((item: any, i: number) => {
        deviceTypeProfiles[subscription.uniqueId] = {
          ...deviceTypeProfiles[subscription.uniqueId],
          [`defaultProfileId${i.toString()}`]: item?.profileId,
        };
      });
    return subscription.uniqueId;
  });

  return {
    serviceId: service.uniqueId,
    subscriptions,
    subscriptionsPricing,
    features,
    deviceTypeProfiles,
  };
};

export const transformAccountServiceDataForAPI = (
  data: IAccountAssignService
) => {
  const subscriptions = data?.subscriptions?.map((id) => {
    const subscriptionPricing = data?.subscriptionsPricing?.[id] || {};
    const _features: any = data.features[id] || {};
    const features = Object.keys(_features).filter(
      (key) => _features[key] === true
    );
    const deviceTypeProfiles: any = data?.deviceTypeProfiles?.[id] || {};
    const result = [];
    const length = Object.keys(deviceTypeProfiles).length / 2; // Assuming equal number of deviceTypeId and defaultProfileId

    for (let i = 0; i < length; i++) {
      const deviceTypeIdKey = `deviceTypeId${i}`;
      const defaultProfileIdKey = `defaultProfileId${i}`;

      if (
        deviceTypeProfiles[deviceTypeIdKey] &&
        deviceTypeProfiles[defaultProfileIdKey]
      ) {
        result.push({
          deviceTypeId: deviceTypeProfiles[deviceTypeIdKey],
          defaultProfileId: deviceTypeProfiles[defaultProfileIdKey],
        });
      }
    }

    return {
      subscriptionUniqueId: id,
      ...subscriptionPricing,
      features,
      deviceTypeProfiles: result,
    };
  });

  return {
    serviceId: data?.serviceId,
    subscriptions,
  };
};

//
// Account Device-Types Helpers
//
export const accountAssignDeviceTypeInitialValues: IAccountAssignDeviceType = {
  deviceTypeId: "",
  currency: 0,
  price: 0,
};

export const accountAssignDeviceTypeValidationSchema: Yup.Schema<IAccountAssignDeviceType> =
  Yup.object({
    deviceTypeId: Yup.string().trim().required().label("Device type"),
    currency: Yup.number().label("Currency").required(),
    price: Yup.number().label("Price").required(),
  });

export const transformAccountDeviceTypeDataForForm = (
  deviceType: any
): IAccountAssignDeviceType => {
  if (isEmpty(deviceType)) return accountAssignDeviceTypeInitialValues;

  return {
    deviceTypeId: deviceType.uniqueId,
    currency: deviceType.currency,
    price: deviceType.price,
  };
};

//
// Account Object-Types Helpers
//
export const accountAssignObjectTypeInitialValues: IAccountAssignObjectType = {
  objectTypeId: "",
};

export const accountAssignObjectTypeValidationSchema: Yup.Schema<IAccountAssignObjectType> =
  Yup.object({
    objectTypeId: Yup.string().trim().required().label("Object type"),
  });

export const formatAlarmOptionalFilters = (alarms: any[]) => {
  const newData: any = [];
  for (let i = 0; i < alarms.length; i++) {
    const optionalFilters: any = [];
    Object.keys(alarms[i]).forEach((key) => {
      if (key.startsWith("of_") && alarms[i][key]) {
        optionalFilters.push(key.split("of_")[1]);
      }
    });
    const { isEnabled, isSupported, uniqueId } = alarms[i];
    newData[i] = {
      optionalFilters,
      isEnabled,
      isSupported,
      uniqueId,
    };
  }
  return newData;
};

export const getAlarmOptionalFilters = (alarms: any[]) => {
  const newData: any = [];
  for (let i = 0; i < alarms.length; i++) {
    let optionalFilters: any = {};
    Object.keys(alarms[i]).forEach((key) => {
      if (key === "optionalFilters") {
        alarms[i].optionalFilters.map((item: string) => {
          optionalFilters[`of_${item}`] = true;
        });
      }
    });
    const { isEnabled, isSupported, uniqueId } = alarms[i];
    newData[i] = {
      ...optionalFilters,
      isEnabled,
      isSupported,
      uniqueId,
    };
  }
  return newData;
};

export const resolveRedirectToCustomer = (
  accountName: string,
  accountUniqueId: string
) => {
  const currentHostname = window.location.hostname;

  const environmentList: Record<string, string> = {
    "dev.admin.allsetra.nl": "https://dev.portal.allsetra.nl",
    "staging.admin.allsetra.nl": "https://staging.portal.allsetra.nl",
    "admin.allsetra.nl": "https://portal.allsetra.nl",
  };

  const defaultURL = "https://dev.portal.allsetra.nl";
  const customerURL = environmentList[currentHostname] ?? defaultURL;

  window.open(
    `${customerURL}/dashboard?accountName=${encodeURIComponent(
      accountName
    )}&uniqueId=${encodeURIComponent(accountUniqueId)}`,
    "_blank"
  );
};
