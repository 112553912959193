import { FC, useEffect, useState } from "react";
import { Box } from "@mui/material";
import {
  TabPanes,
  TabPanel,
  Table,
  TableColumn,
  types,
} from "@vilocnv/allsetra-core";
import {
  DEVICE_DETAILS_TABLES_HEADINGS,
  DEVICE_METADATA_TABLE_COLUMNS_FN,
} from "app/data/constants";

interface IProps {
  deviceMetaData: any[];
}

const DeviceDetailsTables: FC<IProps> = ({ deviceMetaData = [] }) => {
  const [tabSelectedIndex, setTabSelectedIndex] = useState(0);
  const [tableColumns, setTableColumns] = useState<
    TableColumn<types.IDevice>[]
  >([]);

  useEffect(() => {
    if (deviceMetaData.length > 0) {
      let lat = 0;
      let lng = 0;

      const latRow = deviceMetaData.find(
        (meta) => meta?.field?.label.toString()?.toLowerCase() === "latitude"
      );

      const lngRow = deviceMetaData.find(
        (meta) => meta?.field?.label.toString()?.toLowerCase() === "longitude"
      );

      if (latRow?.value && lngRow?.value) {
        lat = latRow?.value;
        lng = lngRow?.value;
      } else {
        const gpsPositionRow = deviceMetaData.find(
          (meta) =>
            meta?.field?.label.toString()?.toLowerCase() === "gpsposition"
        );

        if (gpsPositionRow?.value) {
          const splitted = gpsPositionRow?.value.split(",");

          if (splitted.length === 2) {
            lat = parseFloat(splitted[0].toString().replace(/[^0-9.-]/g, ""));
            lng = parseFloat(splitted[1].toString().replace(/[^0-9.-]/g, ""));
          }
        }
      }

      const columns = DEVICE_METADATA_TABLE_COLUMNS_FN(lat, lng);
      setTableColumns(columns);
    }
  }, [deviceMetaData]);

  return (
    <Box mt={8}>
      <TabPanes
        value={tabSelectedIndex}
        onChange={setTabSelectedIndex}
        headings={DEVICE_DETAILS_TABLES_HEADINGS}
      >
        <TabPanel value={tabSelectedIndex} index={0}>
          {/* Device Details */}
          <Table columns={tableColumns} data={deviceMetaData} />
        </TabPanel>
        <TabPanel value={tabSelectedIndex} index={1}>
          {/* Object Information */}
          <h1>Object Information</h1>
        </TabPanel>
        <TabPanel value={tabSelectedIndex} index={2}>
          {/* CAN Bus data */}
          <h1>CAN Bus data</h1>
        </TabPanel>
        <TabPanel value={tabSelectedIndex} index={3}>
          {/* Device profile */}
          <h1>Device profile</h1>
        </TabPanel>
        <TabPanel value={tabSelectedIndex} index={4}>
          {/* Subscription History */}
          <h1>Subscription History</h1>
        </TabPanel>
        <TabPanel value={tabSelectedIndex} index={5}>
          {/* Alarm History */}
          <h1>Alarm History</h1>
        </TabPanel>
        <TabPanel value={tabSelectedIndex} index={6}>
          {/* Installation History */}
          <h1>Installation History</h1>
        </TabPanel>
      </TabPanes>
    </Box>
  );
};

export default DeviceDetailsTables;
