import { createAsyncThunk } from "@reduxjs/toolkit";
import { Accounts } from "app/data/services";
import { toast, types } from "@vilocnv/allsetra-core";

export const getAllAccountsThunk = createAsyncThunk(
  "accounts/getAllAccountsThunk",
  async () => {
    try {
      const response = await Accounts.getAllAccounts();

      if (response.status === 200) {
        return response.data;
      }
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getAccountsByQueryThunk = createAsyncThunk(
  "accounts/getAccountsByQueryThunk",
  async (params: types.IRecordsAggregationBody) => {
    try {
      const response = await Accounts.getAccountsByQuery(params);

      if (response.status === 200) {
        return response.data;
      }
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const searchAccountsByQueryThunk = createAsyncThunk(
  "accounts/searchAccountsByQueryThunk",
  async (params: types.IRecordsAggregationBody) => {
    try {
      const response = await Accounts.getAccountsByQuery(params);

      if (response.status === 200) {
        return response.data;
      }
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getAccountUsersByQueryThunk = createAsyncThunk(
  "accounts/getAccountUsersByQueryThunk",
  async (params: types.IRecordsAggregationBody) => {
    try {
      const response = await Accounts.getAccountUsersByQuery(params);
      if (response.status === 200) {
        return response.data;
      }
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getUsersBasicInfoThunk = createAsyncThunk(
  "accounts/getUsersBasicInfoThunk",
  async () => {
    try {
      const response = await Accounts.getUsersBasicInfo();
      if (response.status === 200) {
        return response.data;
      }
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getSpecificAccountThunk = createAsyncThunk(
  "accounts/getSpecificAccountThunk",
  async (accountId: string) => {
    try {
      const response = await Accounts.getSpecificAccount(accountId);

      if (response.status === 200) {
        return response.data;
      }

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getAccountsIndustriesThunk = createAsyncThunk(
  "accounts/getAccountsIndustriesThunk",
  async () => {
    try {
      const response = await Accounts.getAccountsIndustries();

      if (response.status === 200) {
        return response.data;
      }

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getAccountsTypesThunk = createAsyncThunk(
  "accounts/getAccountsTypesThunk",
  async () => {
    try {
      const response = await Accounts.getAccountsTypes();

      if (response.status === 200) {
        return response.data;
      }

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const createAccountThunk = createAsyncThunk(
  "accounts/createAccountThunk",
  async (data: any) => {
    try {
      const response = await Accounts.createAccount(data);

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const updateAccountThunk = createAsyncThunk(
  "accounts/updateAccountThunk",
  async ({ accountId, data }: { accountId: string; data: any }) => {
    try {
      const response = await Accounts.updateAccount(accountId, data);
      toast.success("Changes saved successfully!");
      return response;
    } catch (e: any) {
      console.error(e);
      toast.error(
        e?.response?.data?.title || e?.message || "Somthing went wrong"
      );
      throw new Error(e);
    }
  }
);

export const deactivateAccountThunk = createAsyncThunk(
  "accounts/deactivateAccountThunk",
  async (accountId: string) => {
    try {
      const response = await Accounts.deactivateAccount(accountId);

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const activateAccountThunk = createAsyncThunk(
  "accounts/activateAccountThunk",
  async (accountId: string) => {
    try {
      const response = await Accounts.reactivateAccount(accountId);

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const searchMinimalAccountsThunk = createAsyncThunk(
  "accounts/searchMinimalAccountsThunk",
  async (data: any) => {
    try {
      const response = await Accounts.searchMinimalAccounts(data);

      if (response.status === 200) {
        return response.data;
      }
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);
