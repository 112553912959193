import { FC, useMemo, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Formik, FormikHelpers } from "formik";
import { useTheme } from "@mui/material";
import { Topbar, types, PageLoader, toast } from "@vilocnv/allsetra-core";
import { capitalize, isEmpty } from "lodash";
import AccountTabLayout from "components/common/AccountTabLayout/AccountTabLayout";
import AccountDetailsSection from "components/sections/admin/AccountDetailsSection/AccountDetailsSection";

// Data
import { useAppDispatch, useAppSelector } from "hooks";
import { selectActiveAccountState } from "app/data/selectors";
import {
  getSpecificAccountThunk,
  setActiveTabIndex,
  updateAccountThunk,
} from "app/features";
import {
  accountDetailsFormatterForForm,
  accountDetailsValidationSchema,
  resolveRedirectToCustomer,
  signalRGenerateSuccessToastMessage,
} from "app/data/helpers";
import { SignalRService } from "app/data/services";

const AccountDetails: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams();

  // Global State
  const { activeAccount, activeAccountId, loading } = useAppSelector(
    selectActiveAccountState
  );

  useEffect(() => {
    dispatch(setActiveTabIndex(0));
  }, []);

  // Local State
  const [submitting, setSubmitting] = useState(false);

  const initialValues = useMemo(
    () =>
      !isEmpty(activeAccount)
        ? accountDetailsFormatterForForm(activeAccount)
        : {},
    [activeAccount, activeAccountId]
  );

  const saveChangesHandler = async (
    values: types.IAccount,
    formikHelpers: FormikHelpers<types.IAccount>
  ) => {
    setSubmitting(true);
    formikHelpers.setSubmitting(true);

    const { type } = await dispatch(
      updateAccountThunk({ accountId: values?.uniqueId, data: values })
    );

    if (type === "accounts/updateAccountThunk/fulfilled") {
      SignalRService.hubConnection?.on("EventRaised", (event: any) => {
        if (event.eventName === types.BackendEventsEnum.AccountUpdatedEvent) {
          formikHelpers.setSubmitting(false);
          setSubmitting(false);

          toast.success(
            signalRGenerateSuccessToastMessage("Account", event.name, "updated")
          );

          dispatch(getSpecificAccountThunk(params.id ?? ""));
        }
      });
    } else {
      formikHelpers.setSubmitting(false);
      setSubmitting(false);
    }

    formikHelpers.setSubmitting(false);
  };

  const handleRedirectToSelectedAccount = () => {
    if (activeAccount) {
      resolveRedirectToCustomer(activeAccount.name, activeAccount.uniqueId);
    }
  };

  return (
    <main>
      <Formik
        // @ts-ignore
        initialValues={initialValues}
        validationSchema={accountDetailsValidationSchema}
        onSubmit={saveChangesHandler}
        enableReinitialize
        validateOnMount
      >
        {({
          handleSubmit,
          isSubmitting,
          isValid,
          dirty,
          resetForm,
          values,
        }) => (
          <Form>
            <Topbar
              theme={theme}
              title="Account"
              breadcrumbTitle={capitalize(activeAccount?.name) ?? "Go Back"}
              breadcrumbRedirectTo={() => navigate(-1)}
              primaryButton={{
                id: "save",
                variant: "contained",
                text: "Save Changes",
                onClick: handleSubmit,
                loading: isSubmitting || submitting,
                disabled: !dirty ? isValid : !isValid,
              }}
              secondaryButton={{
                id: "cancel",
                variant: "text",
                text: "Cancel",
                onClick: () => {
                  resetForm();
                  navigate("/dashboard/account-manager");
                },
              }}
              thirdButton={{
                id: "redirect",
                variant: "text",
                text: "Log in as account",
                onClick: handleRedirectToSelectedAccount,
                disabled: isEmpty(activeAccount),
                style: {
                  border: 'none',
                  color: theme.palette.primary.main
                }
              }}
            />
            <AccountTabLayout ignoreCachedState={true}>
              {loading ? <PageLoader /> : <AccountDetailsSection />}
            </AccountTabLayout>
          </Form>
        )}
      </Formik>
    </main>
  );
};

export default AccountDetails;
