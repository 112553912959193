import { FC } from "react";
import { FormikSelectField } from "@vilocnv/allsetra-core";
import { Box, Stack } from "@mui/material";
import { useAppSelector } from "hooks/reduxHooks";
import { selectSubscriptionsState } from "app/data/selectors";

interface Props {}

const InnerForm: FC<Props> = () => {
  const { allSubscriptions, loading } = useAppSelector(
    selectSubscriptionsState
  );

  return (
    <Stack spacing={2}>
      <Box mb={2}>
        <FormikSelectField
          label="Select subscription"
          placeholder="Select subscription"
          name="subscriptionId"
          options={allSubscriptions || []}
          loading={loading}
          optionLabelKey="name"
          optionValueKey="uniqueId"
          required
        />
      </Box>
    </Stack>
  );
};

export default InnerForm;
