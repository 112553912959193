import { FC, useState } from "react";
import { Box, useTheme } from "@mui/material";
import {
  Table,
  Topbar,
  useDispatchOnParams,
  types,
} from "@vilocnv/allsetra-core";
import { useNavigate } from "react-router-dom";

// Data
import { useAppSelector } from "hooks";
import { getDevicesByQueryThunk } from "app/features";
import { ALL_DEVICES_TABLE_COLUMNS } from "app/data/constants";
import { selectDevicesState } from "app/data/selectors";
import { useTranslation } from "react-i18next";

const Devices: FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();

  // Global State
  const { totalDevices, allDevices, loading } =
    useAppSelector(selectDevicesState);

  const [searchByValue, setSearchByValue] = useState<string>("serialNumber");

  useDispatchOnParams(getDevicesByQueryThunk, {
    searchByField: searchByValue,
  });

  const handleViewDevice = (device: types.IDevice) => {
    sessionStorage.setItem("previousDevicePath", `/dashboard/devices`);
    navigate(`/dashboard/devices/${device.uniqueId}`);
  };

  const handleDeviceRawCommand = (device: types.IDevice) => {
    navigate(`/dashboard/devices/${device.uniqueId}/raw-command`);
  };

  return (
    <Box>
      <Topbar theme={theme} title={t("drawerMenuLinks.devices")} />
      <Box mx={4}>
        <Table
          columns={ALL_DEVICES_TABLE_COLUMNS}
          data={allDevices}
          progressPending={loading}
          paginationTotalRows={totalDevices}
          searchPlaceholder="Search device"
          onRowClicked={handleViewDevice}
          cellActions={[
            {
              name: "Device raw command",
              onClick: handleDeviceRawCommand,
            },
          ]}
          searchByOptions={[
            // { label: "Name", value: "name" },
            { label: "Serial Number", value: "serialNumber" },
            { label: "Activation Code", value: "activationcode" },
          ]}
          searchByValue={searchByValue}
          setSearchByValue={setSearchByValue}
          hideTotalRows
        />
      </Box>
    </Box>
  );
};

export default Devices;
