import { FC } from "react";
import { Box, useTheme } from "@mui/material";
import { Modal, ModalProps, toast } from "@vilocnv/allsetra-core";
import { Formik, Form, FormikHelpers } from "formik";
import InnerForm from "./InnerForm";
import * as Yup from "yup";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import {
  selectDashboardAccountId,
  selectSubscriptionsState,
} from "app/data/selectors";
import {
  createAccountSubscriptionThunk,
  getAllSubscriptionsByObjectIdThunk,
} from "app/features";

export type Props = Omit<ModalProps, "title" | "children"> & {
  accountId: string;
  deviceId: string;
  objectId: string;
};

const initialValues = {
  subscriptionId: "",
};

const schema = Yup.object().shape({
  subscriptionId: Yup.string().required("Subscription required"),
});

const AddDeviceSubscriptionForm: FC<Props> = ({
  accountId,
  deviceId,
  objectId,
  open,
  onClose,
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { allSubscriptions } = useAppSelector(selectSubscriptionsState);
  const userId = useAppSelector(selectDashboardAccountId);

  const onSubmitHandler = async (
    { subscriptionId }: { subscriptionId: string },
    formikHelpers: FormikHelpers<any>
  ) => {
    try {
      formikHelpers.setSubmitting(true);

      if (!subscriptionId) {
        throw "no-subscription-id";
      }

      const subscription = allSubscriptions.find(
        (sub) => sub.uniqueId === subscriptionId
      );

      if (!subscription) {
        throw "no-subscription";
      }

      const { valuePerMonth, currency } = subscription;

      const data = {
        accountId,
        deviceId,
        subscriptionId: subscriptionId,
        startDate: new Date(),
        startUserId: userId,
        currency,
        valuePerMonth,
        isMigration: false,
      };

      const { type } = await dispatch(
        createAccountSubscriptionThunk({ accountId, data })
      );

      if (type === "subscriptions/createAccountSubscriptionThunk/fulfilled") {
        await dispatch(getAllSubscriptionsByObjectIdThunk(objectId));
        onClose();
        formikHelpers.resetForm();
        toast.success("Subscription added successfully");
      }

      if (type === "subscriptions/createAccountSubscriptionThunk/rejected") {
        throw "create-rejected";
      }
    } catch (error) {
      console.error("createSubscriptionThunk", error);
      toast.error("There was a problem adding the subscription");
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={onSubmitHandler}
      >
        {({ handleSubmit, isSubmitting, isValid, resetForm }) => (
          <Form>
            <Modal
              open={open}
              onClose={() => {
                onClose();
                resetForm();
              }}
              title="Add Subscription"
              primaryBtnProps={{
                type: "submit",
                text: "Add subscription",
                loading: isSubmitting,
                disabled: !isValid,
                onClick: handleSubmit,
              }}
              secondaryBtnProps={{
                text: "Back",
                onClick: () => {
                  onClose();
                  resetForm();
                },
              }}
              theme={theme}
            >
              <InnerForm />
            </Modal>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default AddDeviceSubscriptionForm;
