import { createAsyncThunk } from "@reduxjs/toolkit";
import UtilityService from "app/data/services/UtilityService";

export const getGeocodedLocationThunk = createAsyncThunk(
  "utilities/getGeocodedLocationThunk",
  async ({ latitude, longitude }: { latitude: number; longitude: number }) => {
    try {
      const utilityService = new UtilityService();

      const response = await utilityService.getGeocodedLocation(
        latitude,
        longitude
      );
      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);
