import { FC, Fragment, useCallback, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, useTheme } from "@mui/material";
import { Settings } from "@mui/icons-material";
import { Topbar, PageLoader, types } from "@vilocnv/allsetra-core";
import DeviceDetailsHeader from "components/sections/devices/DeviceDetailsHeader/DeviceDetailsHeader";
import DeviceDetailsBody from "components/sections/devices/DeviceDetailsBody/DeviceDetailsBody";
import DeviceDetailsTables from "components/sections/devices/DeviceDetailsTables/DeviceDetailsTables";
import DeviceDetailsEvents from "components/sections/devices/DeviceDetailsEvents/DeviceDetailsEvents";

// Data
import { useActiveDevice, useAppDispatch, useAppSelector } from "hooks";
import {
  appendEventsMetaData,
  getAllSubscriptionsByDeviceIdThunk,
} from "app/features";
import { selectDeviceSubscriptionsById } from "app/data/selectors";
import { SignalRService } from "app/data/services";
import useBackButtonHandler from "app/data/helpers/useBackButtonHandler";

const DeviceDetails: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const initialStateRef = useRef(location.state);

  const { specificDevice, deviceId } = useActiveDevice({
    ignoreCachedState: true,
  });

  const { deviceSubscriptions, deviceSubscriptionsLoading } = useAppSelector(
    selectDeviceSubscriptionsById
  );

  useBackButtonHandler();

  const handleBackNavigation = useCallback(() => {
    const basePath = "/dashboard/devices";
    const previousDevicePath = sessionStorage.getItem("previousDevicePath");
    const previousPath = initialStateRef?.current?.from;

    if (previousPath || previousDevicePath) {
      const searchParams = new URLSearchParams(location.search);
      navigate(
        `${previousPath || previousDevicePath}?${searchParams.toString()}`
      );
    } else {
      navigate(basePath);
    }
  }, [navigate, location]);

  useEffect(() => {
    if (location.state) {
      initialStateRef.current = location.state;
    }
  }, []);

  useEffect(() => {
    if (SignalRService.hubConnection?.state === "Connected") {
      SignalRService.hubConnection?.on("EventRaised", (event: any) => {
        if (
          event.eventName ===
            types.BackendEventsEnum.DeviceMetadataParsedEvent &&
          specificDevice?.uniqueId === event?.uniqueId
        ) {
          dispatch(appendEventsMetaData(event));
        }
      });
    }

    if (deviceId) {
      dispatch(getAllSubscriptionsByDeviceIdThunk(deviceId));
    }
  }, []);

  return (
    <Box>
      <Topbar
        theme={theme}
        title="Device details"
        breadcrumbTitle="Devices"
        breadcrumbRedirectTo={handleBackNavigation}
        primaryButton={{
          id: "settings",
          variant: "contained",
          text: "Settings",
          startIcon: <Settings />,
          onClick: () =>
            navigate(`/dashboard/devices/${specificDevice?.uniqueId}/settings`),
        }}
      />
      {deviceSubscriptionsLoading ? (
        <PageLoader />
      ) : (
        <Fragment>
          <Box mx={4} mt={4}>
            <DeviceDetailsHeader specificDevice={specificDevice} />
            <DeviceDetailsBody
              specificDevice={specificDevice}
              objectSubscriptions={deviceSubscriptions || []}
            />
          </Box>
          <DeviceDetailsTables
            deviceMetaData={specificDevice?.metadata || []}
          />
          <DeviceDetailsEvents
            specificDevice={specificDevice}
            deviceId={deviceId}
          />
        </Fragment>
      )}
    </Box>
  );
};

export default DeviceDetails;
