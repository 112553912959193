import { Box } from "@mui/material";
import {
  ContentSectionLayout,
  FormikInputField,
  FormikToggleField,
} from "@vilocnv/allsetra-core";

const AccountNotificationSection: React.FC = () => {
  return (
    <Box>
      <ContentSectionLayout
        title="Private rides notifications"
        subTitle="Track the mileage that a driver and an object have driven privately during a calendar year."
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
            marginTop: {
              xs: "32px",
              md: "0px",
            },
          }}
        >
          <FormikToggleField
            label="Receive notifications about Private rides"
            name="isYearMileageNotificationEnabled"
          />

          <FormikInputField
            label="Set a mileage threshold per year (km)"
            name="yearMileageThreshold"
            placeholder="500"
            fullWidth
          />
        </Box>
      </ContentSectionLayout>
    </Box>
  );
};

export default AccountNotificationSection;
