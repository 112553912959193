import * as Yup from "yup";
import { IAddField } from "../types";

export const addFieldInitialValues: IAddField = {
  label: "",
  isRequired: false,
  fieldType: 0,
  maxLength: "",
  onlyNumbers: false,
  customerPortal: null,
  customerApp: null,
  adminPortal: null,
  installationApp: null,
  valueFactor: "",
  showTagLabel: false,
  valueUnit: "",
  successValue: "",
  failureValue: "",
  warningValue: "",
  infoValue: "",
  optionValues: [],
  isDataPoint: false,
  iconId: null,
  isReadOnly: false,
};

export const addFieldValidationSchema = Yup.object({
  label: Yup.string().trim().required().label("Field label"),
  fieldType: Yup.number().label("Field type"),
  maxLength: Yup.string().required().label("Max length"),
  isRequired: Yup.boolean().nullable().label("Field is required"),
  onlyNumbers: Yup.boolean().nullable().label("Only numbers"),
  customerPortal: Yup.number().nullable(),
  customerApp: Yup.number().nullable(),
  adminPortal: Yup.number().nullable(),
  installationApp: Yup.number().nullable(),
  valueFactor: Yup.string().nullable(),
  showTagLabel: Yup.boolean().nullable(),
  valueUnit: Yup.string().nullable(),
  successValue: Yup.string().nullable(),
  failureValue: Yup.string().nullable(),
  warningValue: Yup.string().nullable(),
  infoValue: Yup.string().nullable(),
  optionValues: Yup.array().of(Yup.string()).nullable(),
  isDataPoint: Yup.boolean().nullable(),
  iconId: Yup.string().nullable(),
  uniqueId: Yup.string().nullable(),
  isReadOnly: Yup.boolean().nullable(),
});
