import { Box, Stack } from "@mui/material";
import {
  DetailsButtonText,
  ObjectInfoHeading,
} from "components/sections/devices/DeviceDetailsBody/DeviceDetailsBody.styled";
import { NavigateFunction } from "react-router-dom";
import {
  SmallText,
  StyledAccessTimeIcon,
} from "components/cards/DeviceDetailsCard/DeviceDetailsCard.styled";
import { isEmpty, omit } from "lodash";
import moment from "moment";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import NearMeRoundedIcon from "@mui/icons-material/NearMeRounded";
import SquareIcon from "@mui/icons-material/Square";
import { checkValidDate, getParentAccountName } from "../constants";
import { types } from "@vilocnv/allsetra-core";
import GoogleMapLinkTag from "components/common/Map/GoogleMapLinkTag/GoogleMapLinkTag";

//
// DEVICE DETAILS PAGE HELPERS
//
const GetValueForObjectNameGenInfo = (
  name: string,
  objectId: string,
  navigate: NavigateFunction
) => {
  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      spacing={4}
    >
      <Box>{name ?? "N/A"}</Box>
      <DetailsButtonText
        id="connected-device-1"
        onClick={() =>
          navigate({
            pathname: `/dashboard/${objectId}`,
          })
        }
      >
        Details
      </DetailsButtonText>
    </Stack>
  );
};

export const transformDeviceForGeneralInfoTable = (
  device: types.IDevice | null,
  navigate: NavigateFunction
): any => {
  if (!device) return {};
  return {
    "Device type": device?.deviceType?.name ?? "N/A",
    Account:
      device?.object?.accounts.length > 0
        ? device?.object?.accounts.map((acc: any, i: number) => (
            <DetailsButtonText
              id={`connected-account-${i}`}
              onClick={() =>
                navigate("/dashboard/account-manager/details/" + acc?.uniqueId)
              }
            >
              {acc?.name}
            </DetailsButtonText>
          ))
        : "N/A",
    "Object Name": GetValueForObjectNameGenInfo(
      device?.object?.name || "-",
      device?.object?.uniqueId,
      navigate
    ),
    "Activation Code": device?.activationCode || "N/A",
    "Device Profile":
      device?.deviceType?.deviceProfiles.map((devProf: any) => (
        <div>{devProf?.name}</div>
      )) || "-",
    "Device Modules":
      device?.deviceModules.map((devMod: any) => (
        <div>{devMod?.moduleName}</div>
      )) || "-",
  };
};

const getValueForObjectInformation = ({
  heading,
  subitems,
  timeStamp,
}: {
  heading: string;
  subitems?: Array<any>;
  timeStamp?: string;
}) => (
  <Stack spacing={1}>
    {heading && <ObjectInfoHeading>{heading}</ObjectInfoHeading>}
    {subitems &&
      subitems.map((item) => (
        <SmallText key={item.text} color={"#323946"}>
          {item.text}
        </SmallText>
      ))}
    {timeStamp && (
      <Stack direction={"row"} alignItems={"center"} spacing={1}>
        <StyledAccessTimeIcon />
        <SmallText color={"#959EB2"}>{timeStamp}</SmallText>
      </Stack>
    )}
  </Stack>
);

export const getTimeDifference = (lastUpdated: string) => {
  const lastUpdatedDate = new Date(lastUpdated);
  const currentTime = new Date();

  const timeDifference = currentTime.getTime() - lastUpdatedDate.getTime();

  const seconds = Math.floor(timeDifference / 1000) % 60;
  const minutes = Math.floor(timeDifference / (1000 * 60)) % 60;
  const hours = Math.floor(timeDifference / (1000 * 60 * 60)) % 24;
  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  const monthsDiff =
    (currentTime.getFullYear() - lastUpdatedDate.getFullYear()) * 12 +
    (currentTime.getMonth() - lastUpdatedDate.getMonth());

  return { seconds, minutes, hours, days, months: monthsDiff };
};

const handleAddressClick = (latitude: string, longitude: string) => {
  window.open(
    `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`
  );
};

function formatTimeAgo(
  dateStr: string | Date,
  inputFormat: string = "YYYYMMDD"
) {
  const date = inputFormat ? moment(dateStr, inputFormat) : moment(dateStr);
  const now = moment();

  const diffMs = now.diff(date);
  const duration = moment.duration(diffMs);

  if (diffMs < 24 * 60 * 60 * 1000) {
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    return `${hours > 0 ? hours + "h " : ""}${minutes}m ago`;
  }

  return date.fromNow();
}

export const transformDeviceForObjectInfoTable = (
  device: any | null,
  objectSubscriptions: Array<any>,
  minimalSearchedAccounts: Array<any>
): any => {
  if (!device) return {};
  const { days, months } = getTimeDifference(device.installationDate);
  const currency: any = {
    1: "$",
    0: "€",
  };

  const locationHeading: any = () => {
    const content =
      device?.location && device?.location?.resolvedAddress
        ? device?.location?.resolvedAddress
        : "N/A";

    return (
      <GoogleMapLinkTag
        latitude={device.location?.latitude}
        longitude={device.location?.longitude}
        style={{
          color: undefined,
          textDecoration: undefined,
        }}
      >
        {content}
      </GoogleMapLinkTag>
    );
  };

  const locationSubitems: any[] = [
    {
      text: `${
        device.location?.locationProvider?.locationType || ""
      } Location, ${
        device?.location?.accuracy
          ? `± ${device?.location?.accuracy?.toFixed(0)} m`
          : "N/A"
      }`,
    },
  ];

  const locationTimeStamp: any = checkValidDate(device?.location?.date)
    ? `${formatTimeAgo(device?.location?.date)} (${moment(
        device?.location?.date
      ).format("MMM DD, YYYY HH:mm")})`
    : "N/A";

  const networkHeading: any = () => {
    const rssiRow = device?.metadata?.find(
      (meta: any) => meta?.field?.label?.toString()?.toLowerCase() === "rssi"
    );
    const snrRow = device?.metadata?.find(
      (meta: any) => meta?.field?.label?.toString()?.toLowerCase() === "snr"
    );

    const rssi = rssiRow?.value ?? undefined;
    const snr = snrRow?.value ?? undefined;

    return (
      <div style={{ display: "flex", gap: "2rem" }}>
        <span>{rssi && `RSSI: ${rssi} dBm`}</span>

        <span>{snr && `SNR: ${snr} dBm`}</span>
      </div>
    );
  };

  const networkSubitems: any = () => {
    const lrnRow = device?.metadata?.find(
      (meta: any) =>
        meta?.field?.label?.toString()?.toLowerCase() === "lastreceivednetwork"
    );

    const lastReceivedNetwork = lrnRow?.value ?? undefined;

    return [{ text: `Network: ${lastReceivedNetwork ?? "N/A"}` }];
  };

  const networkTimeStamp: any = () => {
    return checkValidDate(device?.lastUpdated)
      ? `${formatTimeAgo(device?.lastUpdated)} (${moment(
          device?.lastUpdated
        ).format("MMM DD, YYYY HH:mm")})`
      : "N/A";
  };

  const installDateTimeStamp: any = () => {
    return checkValidDate(device?.installationDate)
      ? `${formatTimeAgo(device?.installationDate)} (${moment(
          device?.installationDate
        ).format("MMM DD, YYYY HH:mm")})`
      : "N/A";
  };

  return {
    LOCATION: getValueForObjectInformation({
      heading: locationHeading(),
      subitems: locationSubitems,
      timeStamp: locationTimeStamp,
    }),
    NETWORK: getValueForObjectInformation({
      heading: networkHeading(),
      subitems: networkSubitems(),
      timeStamp: networkTimeStamp(),
    }),
    "INSTALL DATE": getValueForObjectInformation({
      heading: device?.installationDate
        ? `${(days > 30 ? `${months} months` : `${days} days`) || "-"} ago`
        : "-",
      timeStamp: installDateTimeStamp(),
    }),
    SUBSCRIPTION: objectSubscriptions.length
      ? objectSubscriptions.map((subscription: any) => {
          const { days, months } = getTimeDifference(subscription.startDate);

          return getValueForObjectInformation({
            heading: subscription?.startDate
              ? `${
                  (days > 30 ? `${months} months` : `${days} days`) || "-"
                } ago`
              : "N/A",
            subitems: [
              {
                text: `Contract duration: ${
                  subscription.subscription?.contractTerm > -1
                    ? subscription.subscription.contractTerm + 1 * 12
                    : "N/A"
                } months`,
              },
              {
                text: `Plan price: ${
                  subscription.subscription?.currency > -1
                    ? currency[subscription.subscription.currency]
                    : "N/A"
                }${
                  subscription?.valuePerMonth > -1
                    ? subscription?.valuePerMonth
                    : "N/A"
                } `,
              },
              {
                text: `Invoice Owner: ${
                  subscription?.invoiceOwnerName || "N/A"
                }`,
              },
              {
                text: `Service Name: ${subscription?.serviceName || "N/A"}`,
              },
              {
                text: `SubscriptionType/Name: ${
                  subscription?.subscriptionType || "N/A"
                }`,
              },
              {
                text: `Subscription status: ${
                  subscription?.subscriptionStatus
                    ? `${
                        subscription?.subscriptionStatus === 1
                          ? "Active"
                          : "In Active"
                      }`
                    : "N/A"
                }`,
              },
              {
                text: `Account Name: ${getParentAccountName(
                  minimalSearchedAccounts,
                  subscription?.accountId
                )}`,
              },
            ],
          });
        })
      : "No Subscriptions available",
  };
};

//
// DEVICE SETTINGS PAGE HELPERS
//
export const deviceDetailsFormatterForSettingsForm = (
  device: types.IDevice | null
) => {
  if (isEmpty(device)) return {};

  const removedUnwantedKeys = omit(device, [
    "created",
    "createdBy",
    "deleted",
    "deletedBy",
    "isDeleted",
    "lastUpdated",
    "updatedBy",
  ]);

  const formattedDevice = {
    ...removedUnwantedKeys,
    mappings: device?.mappings?.map((item: any) => ({
      dataPointId: item?.dataPoint?.id,
      dynamicFields: item?.dynamicFields,
      formula: item?.formula,
      identifierId: item?.identifier.id,
      isInverted: item?.isInverted,
      triggerMode: item?.triggerMode,
      uniqueId: item?.uniqueId,
    })),
  };

  return formattedDevice;
};

//
// DEVICE LOCATION HISTORY PAGE HELPERS
//

export const timeLineColors = [
  "#1CA0BD",
  "#4560E4",
  "#3E34C1",
  "#6E44A8",
  "#BC3FB7",
];

export const objectRideItems = [
  {
    title: "Start of the ride",
    address: "Lageweg 2a, 7047 CA Braamt, Netherlands",
    timelineDotDetails: <LocationOnIcon />,
    color: "#1CA0BD",
    height: "90px",
  },
  {
    title: "Moving",
    address: "Lageweg 2a, 7047 CA Braamt, Netherlands",
    timelineDotDetails: <NearMeRoundedIcon />,
    color: "#4560E4",
    height: "90px",
  },
  {
    title: "Stopped",
    address: "Lageweg 2a, 7047 CA Braamt, Netherlands",
    timelineDotDetails: <SquareIcon />,
    color: "red",
    height: "90px",
  },
  {
    title: "Moving",
    address: "Lageweg 2a, 7047 CA Braamt, Netherlands",
    timelineDotDetails: <NearMeRoundedIcon />,
    color: "#6E44A8",
    height: "90px",
  },
  {
    title: "End of the ride",
    address: "Lageweg 2a, 7047 CA Braamt, Netherlands",
    timelineDotDetails: <LocationOnIcon />,
    color: "#BC3FB7",
    height: "90px",
  },
];
