import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const useBackButtonHandler = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleBackButton = (event: PopStateEvent) => {
      event.preventDefault(); // Prevents default behavior if needed
      navigate(-1); // Navigate to the previous page
    };

    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [navigate]);
};

export default useBackButtonHandler;
