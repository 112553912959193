import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
// @ts-ignore
import indexedDBStorage from "redux-persist-indexeddb-storage";
import rootReducer from "./features/rootReducer";
import rootRTKQuery from "./features/rootRTKQuery";
import * as Sentry from "@sentry/react";
import packageJson from "../../package.json";

const APP_VERSION = packageJson.version;
const VERSION_KEY = "app_version";

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

const persistConfig = {
  key: "root",
  storage: indexedDBStorage("adminDB", "adminStore"),
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: {
    rootReducer: persistedReducer,
    [rootRTKQuery.reducerPath]: rootRTKQuery.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(rootRTKQuery.middleware),
  enhancers: (defaultEnhancers) => [...defaultEnhancers, sentryReduxEnhancer],
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);

const checkAndClearCache = async () => {
  const currentVersion = localStorage.getItem(VERSION_KEY);

  console.info("Cache Check -", APP_VERSION);

  if (currentVersion !== APP_VERSION) {
    console.info("Cache Check - App version has changed. Clearing cache...");
    await clearCache();
    localStorage.setItem(VERSION_KEY, APP_VERSION);
  }
};

export const clearCache = async () => {
  localStorage.removeItem("persist:root");
  return persistor.purge();
};

checkAndClearCache().then(() => {
  console.info("Cache Check - Version verification complete.");
});

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
