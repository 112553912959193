import { Stack } from "@mui/material";
import { TableColumn, StatusBadge, Badge, types } from "@vilocnv/allsetra-core";
import moment from "moment";
import { isEmpty } from "lodash";
import { Link } from "react-router-dom";
import { searchMinimalAccountsThunk } from "app/features";

//
// TAB PANES HEADINGS
//
export const ACCOUNT_DETAILS_TABS_HEADINGS: string[] = [
  "Details",
  "Services",
  "Device Types",
  "Object Types",
  "Groups",
  "Users",
  "Keys",
  "Subscriptions",
  "Devices",
  "Objects",
  "Alarms",
  "Installations",
  "Notifications",
];

export const ACCOUNT_TAB_INDEX_TO_ROUTENAME_MAPPING: Record<number, string> = {
  0: "details",
  1: "services",
  2: "device-types",
  3: "object-types",
  4: "groups",
  5: "users",
  6: "keys",
  7: "subscriptions",
  8: "devices",
  9: "objects",
  10: "alarms",
  11: "installations",
  12: "notifications",
};

//
// TABLE HEADERS
//
export const getParentAccountName = (
  minimalSearchedAccounts: any,
  parentId: string
) => {
  let parentName = "N/A";
  for (let i = 0; i < minimalSearchedAccounts?.length; i++) {
    if (parentId === minimalSearchedAccounts[i]?.uniqueId) {
      parentName = minimalSearchedAccounts[i]?.name;
      break;
    }
  }
  return parentName;
};

const fetchParentName = async (dispatch: any, parentId: string) => {
  const { type, payload } = await dispatch(
    searchMinimalAccountsThunk({
      itemsPerPage: 10,
      page: 1,
      where: [
        {
          field: "uniqueId",
          value: parentId,
          type: 0,
        },
      ],
    })
  );
  return payload?.results[0]?.name;
};

export const ALL_ACCOUNTS_TABLE_COLUMNS = (minimalSearchedAccounts: any) => {
  const columns: TableColumn<types.IAccount>[] = [
    {
      name: "Name",
      selector: (row: types.IAccount) => row?.name || "N/A",
      sortable: true,
      wrap: true,
    },
    {
      name: "Type",
      selector: (row: types.IAccount) => row?.customerType || "N/A",
      sortable: true,
    },
    {
      name: "Parent Account Name",
      selector: (row: any) => row?.parentName || "N/A",
      sortable: true,
      wrap: true,
    },
    {
      name: "# of users",
      selector: (row: types.IAccount) => row?.usersCount || "N/A",
      sortable: true,
    },
    {
      name: "Status",
      cell: (row: types.IAccount) => (
        <StatusBadge isDeactivated={row.isDeleted} />
      ),
      sortable: true,
    },
  ];
  return columns;
};

export const ACCOUNT_USERS_TABLE_COLUMNS = (roles: any) => {
  const getRole = (roleId: string) => {
    const found = roles?.find((item: any) => item.id === roleId);
    return found?.name || "N/A";
  };
  return [
    {
      name: "Status",
      cell: (row: types.IUser) => <StatusBadge isDeactivated={row.isDeleted} />,
      sortable: true,
    },
    {
      name: "Full name",
      selector: (row: types.IUser) =>
        `${row.firstName || ""} ${row.lastName || ""}`,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row: types.IUser) => row?.email || "N/A",
      sortable: true,
    },
    {
      name: "Role",
      selector: (row: types.IUser) => (
        <Badge colorScheme={"info"}>{getRole(row?.role)}</Badge>
      ),
      sortable: true,
    },
    {
      name: "Phone Number",
      selector: (row: types.IUser) => row?.phone || "N/A",
      sortable: true,
    },
    {
      name: "Priority",
      selector: (row: types.IUser) => row?.priority?.toString() || "N/A",
      sortable: true,
    },
    {
      name: "Alarm Contact",
      selector: (row: types.IUser) => (
        <Badge colorScheme={row?.isAlarmContact ? "success" : "error"}>
          {row?.isAlarmContact ? "Yes" : "No"}
        </Badge>
      ),
      sortable: true,
    },
  ];
};

export const ACCOUNT_SERVICES_TABLE_COLUMNS: TableColumn<any>[] = [
  {
    name: "Name",
    selector: (row: any) => row.name,
    sortable: true,
  },
  {
    name: "Status",
    cell: (row: any) => <StatusBadge isDeactivated={row.isDeleted} />,
    sortable: true,
  },
  {
    name: "Service Fields",
    selector: (row: any) => row.fieldsCount,
    sortable: true,
  },
  {
    name: "Linked Subscriptions Types",
    selector: (row: any) => row.subscriptions,
    format: (row: any) => (
      <Stack my={1} gap={1} flexWrap={"wrap"}>
        {row.subscriptions.map((service: any) => (
          <Link
            to={`/dashboard/account-manager/subscriptions/${service.uniqueId}/alarm-config`}
          >
            <Badge colorScheme="info">{service?.name || "N/A"}</Badge>
          </Link>
        ))}
      </Stack>
    ),
  },
  {
    name: "Linked Object Types",
    selector: (row: any) => row.objectTypesCount,
    sortable: true,
  },
];

export const ACCOUNT_DEVICE_TYPES_TABLE_COLUMNS: TableColumn<any>[] = [
  {
    name: "Device Name",
    selector: (row: any) => row.name,
    sortable: true,
  },
  {
    name: "Manufacturer Name",
    selector: (row: any) => row.deviceManufacturerName || "",
    sortable: true,
  },
  {
    name: "Status",
    cell: (row: any) => <StatusBadge isDeactivated={row.isDeleted} />,
    sortable: true,
  },
  {
    name: "Device Profiles",
    selector: (row: any) => row.deviceProfiles,
    format: (row: any) => (
      <Stack my={1} gap={1} flexWrap={"wrap"}>
        {row.deviceProfiles.map(({ name }: any) => (
          <Badge colorScheme="info">{name}</Badge>
        ))}
      </Stack>
    ),
    sortable: true,
  },
];

export const ACCOUNT_OBJECT_TYPES_TABLE_COLUMNS: TableColumn<any>[] = [
  {
    name: "Name",
    selector: (row: any) => row.name,
    sortable: true,
  },
  {
    name: "Status",
    cell: (row: any) => <StatusBadge isDeactivated={row.isDeleted} />,
    sortable: true,
  },
  {
    name: "# of Objects",
    selector: (row: any) => `${row.objectsCount} objects`,
    sortable: true,
  },
  {
    name: "# of Services",
    selector: (row: any) => `${row.servicesCount} services`,
    sortable: true,
  },
  {
    name: "# of Fields",
    selector: (row: any) => `${row.fieldsCount} fields`,
    sortable: true,
  },
];

const handleObjectNameClick = (navigate: any, row: any) => {
  navigate({
    pathname: `/dashboard/${row.uniqueId}`,
  });
};

export const ACCOUNT_OBJECTS_TABLE_COLUMNS = (navigate: any) => {
  const columns: TableColumn<types.IObject>[] = [
    {
      name: "Name",
      selector: (row: types.IObject) => (
        <div onClick={() => handleObjectNameClick(navigate, row)}>
          {row.name}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Object Type",
      //@ts-ignore
      selector: (row: types.IObject) => row?.objectTypeName || "N/A",
      sortable: true,
    },
    {
      name: "A-NR",
      selector: (row: types.IObject) => row?.aNumber || "N/A",
      sortable: true,
    },
    {
      name: "Multiviewer  Name",
      selector: (row: types.IObject) => row?.multiviewerName || "N/A",
      sortable: true,
    },
    {
      name: "Mileage",
      selector: (row: types.IObject) => row?.mileage || "N/A",
      sortable: true,
      maxWidth: "100px",
    },
    {
      name: "Status",
      cell: (row: types.IObject) => (
        <StatusBadge isDeactivated={row.isDeleted} />
      ),
      sortable: true,
      maxWidth: "100px",
    },
    {
      name: "Last Communication",
      selector: (row: any) =>
        checkValidDate(row.lastUpdated)
          ? moment(row.lastUpdated).format("MMMM DD, YYYY HH:mm")
          : "N/A",
      sortable: true,
    },
    {
      name: "Last known address",
      selector: (row: any) => row?.location?.resolvedAddress || "N/A",
      sortable: true,
    },
  ];
  return columns;
};

export const ACCOUNT_DEVICES_TABLE_COLUMNS: TableColumn<types.IDevice>[] = [
  {
    name: "Name",
    selector: (row: types.IDevice) => row.name,
    sortable: true,
  },
  {
    name: "Device Type",
    selector: (row: types.IDevice) => row.deviceType?.name || "",
    sortable: true,
  },
  {
    name: "Last Received Network",
    selector: (row: types.IDevice) => row.lastReceivedNetwork,
    sortable: true,
  },
  {
    name: "Last BatteryValue",
    selector: (row: types.IDevice) => row.lastBatteryValue,
    sortable: true,
  },
  {
    name: "Status",
    cell: (row: types.IDevice) => <StatusBadge isDeactivated={row.isDeleted} />,
    sortable: true,
  },
];

export const ACCOUNT_INSTALLATIONS_TABLE_COLUMNS: TableColumn<any>[] = [
  {
    name: "Status",
    cell: (row: any) => (
      <Badge
        colorScheme={
          row?.status == 1 ? "success" : row?.status == 0 ? "warning" : "info"
        }
      >
        {row?.status == 1
          ? "COMPLETED"
          : row?.status == 0
          ? "IN PROGRESS"
          : "FAILED"}
      </Badge>
    ),
    sortable: true,
  },
  {
    name: "Last Updated",
    cell: (row: any) =>
      checkValidDate(row?.lastUpdated)
        ? moment(row?.lastUpdated).format("MMMM DD, YYYY HH:mm")
        : "N/A",
    sortable: true,
  },
  {
    name: "Current Step",
    selector: (row: any) => row?.currentStep || "N/A",
    sortable: true,
  },
  {
    name: "Object",
    selector: (row: any) => row?.object?.name || "N/A",
    sortable: true,
  },
  {
    name: "Object Type",
    selector: (row: any) => row?.objectType?.name || "N/A",
    sortable: true,
  },
  {
    name: "Serial Number",
    selector: (row: any) => row?.device?.serialNumber || "N/A",
    sortable: true,
  },
  {
    name: "Device Type",
    selector: (row: any) => row?.deviceType?.name || "N/A",
    sortable: true,
  },
];

export const ACCOUNT_SUBSCRIPTIONS_TABLE_COLUMNS: TableColumn<any>[] = [
  {
    name: "Name",
    selector: (row: any) => row.subscription?.name || "",
    sortable: true,
  },
  {
    name: "Duration In Months",
    selector: (row: any) => row.subscription?.durationInMonths || "",
    sortable: true,
  },
  {
    name: "Service Name",
    selector: (row: any) => row.subscription?.service?.name || "",
    sortable: true,
  },
  {
    name: "Status",
    cell: (row: any) => (
      <StatusBadge isDeactivated={row.subscription?.isDeleted} />
    ),
    sortable: true,
  },
];

export const ACCOUNT_KEYS_TABLE_COLUMNS: TableColumn<types.IKey>[] = [
  {
    name: "Label",
    selector: (row: types.IKey) => row.label || "",
    sortable: true,
  },
  {
    name: "Key ID",
    selector: (row: types.IKey) => row.keyId || "",
    sortable: true,
  },
  {
    name: "Status",
    cell: (row: types.IKey) => <StatusBadge isDeactivated={!row.isActive} />,
    sortable: true,
  },
  {
    name: "Activation Time Stamp",
    selector: (row: types.IKey) =>
      checkValidDate(row.activationDate)
        ? moment(row.activationDate).format("YYYY-MM-DD HH:MM:SS")
        : "N/A",
    sortable: true,
  },
  {
    name: "Deactivation Time Stamp",
    selector: (row: types.IKey) =>
      checkValidDate(row.deactivationDate)
        ? moment(row.deactivationDate).format("YYYY-MM-DD HH:MM:SS")
        : "N/A",
    sortable: true,
  },
];

export const checkValidDate = (date: any) => {
  try {
    if (isEmpty(date)) return false;
    const minDate = new Date("0001-01-01T00:00:00Z");
    const givenDate = new Date(date);
    if (givenDate > minDate) return true;
    else return false;
  } catch (e: any) {
    return false;
  }
};
