import axiosInstance from "app/axiosInstance";
import { IGeocodedLocation } from "../types/locationTypes";

class UtilityService {
  getGeocodedLocation = async (
    latitude: number,
    longitude: number
  ): Promise<IGeocodedLocation | undefined> => {
    if (latitude === 0 || longitude === 0) return undefined;

    const response = await axiosInstance.get<IGeocodedLocation>(
      `/utility/reversegeocode?latitude=${latitude}&longitude=${longitude}`
    );
    return response.data;
  };
}

export default UtilityService;
