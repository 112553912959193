import { FC, useMemo, useState } from "react";
import { Box, useTheme } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { FormikHelpers } from "formik";
import {
  Table,
  Topbar,
  DeleteConfirmationModal,
  useDispatchOnParams,
  types,
  toast,
  utils,
} from "@vilocnv/allsetra-core";

// Data
import { useAppDispatch, useAppSelector, useDispatchOnMount } from "hooks";
import { getAllSubscriptionsTableColumns } from "app/data/constants";
import { selectSubscriptionsState } from "app/data/selectors";
import { IAddSubscription, ISubscription } from "app/data/types";
import { subscriptionDataFormatterForForm } from "app/data/helpers";
import SubscriptionForm from "components/forms/admin/SubscriptionForm/SubscriptionForm";
import { isEmpty } from "lodash";
import {
  createOrUpdateSubscriptionThunk,
  deactivateSubscriptionThunk,
  getSpecificSubscriptionThunk,
  getSubscriptionsByQueryThunk,
  reactivateSubscriptionThunk,
  getAllSubscriptionTypesThunk,
} from "app/features";
import { useNavigate } from "react-router-dom";
import { SignalRService } from "app/data/services";
import { useTranslation } from "react-i18next";

const SubscriptionManager: FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Global State
  const {
    totalSubscriptions,
    allSubscriptions,
    specificSubscription,
    subscriptionTypes,
    totalSubscriptionsLoading,
  } = useAppSelector(selectSubscriptionsState);

  // Local State
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState<
    string | null
  >(null);
  const [open, setOpen] = useState(false); // Boolean state for Add Subscription Form Modal
  const [openDeleteModal, setOpenDeleteModal] = useState(false); // Boolean state for DeleteConfirmationModal Modal
  const [isSubmitting, setIsSubmitting] = useState(false);

  useDispatchOnParams(getSubscriptionsByQueryThunk);

  useDispatchOnMount(
    getAllSubscriptionTypesThunk,
    subscriptionTypes.length ? undefined : true
  );

  const handleActivateSubscription = async (subscription: ISubscription) => {
    if (isEmpty(subscription)) return;

    const { type } = await dispatch(
      reactivateSubscriptionThunk(subscription.uniqueId)
    );

    if (type === "subscriptions/reactivateSubscriptionThunk/fulfilled") {
      SignalRService.hubConnection?.on("EventRaised", (event: any) => {
        if (
          event.eventName === types.BackendEventsEnum.SubscriptionActivatedEvent
        ) {
          toast.success("Subscription has been activated");

          dispatch(getSubscriptionsByQueryThunk(utils.getCommonParamsForApi()));
        }
      });
    } else {
      toast.error("Server side error occured.");
    }
  };

  const openDeleteConfirmationModal = (subscription: ISubscription) => {
    setSelectedSubscriptionId(subscription.uniqueId);
    setOpenDeleteModal(true);
  };

  const handleDeactivateSubscription = async () => {
    if (isEmpty(selectedSubscriptionId)) return;

    setIsSubmitting(true);

    const { type } = await dispatch(
      deactivateSubscriptionThunk(selectedSubscriptionId ?? "")
    );

    if (type === "subscriptions/deactivateSubscriptionThunk/fulfilled") {
      SignalRService.hubConnection?.on("EventRaised", (event: any) => {
        if (
          event.eventName ===
          types.BackendEventsEnum.SubscriptionDeactivatedEvent
        ) {
          setOpenDeleteModal(false);
          setIsSubmitting(false);

          toast.success("Subscription has been deactivated");

          dispatch(getSubscriptionsByQueryThunk(utils.getCommonParamsForApi()));
        }
      });
    } else {
      setIsSubmitting(false);
      toast.error("Server side error occured .");
    }
  };

  const addSubscriptionHandler = async (
    values: IAddSubscription,
    formikHelpers: FormikHelpers<IAddSubscription>
  ) => {
    formikHelpers.setSubmitting(true);
    const { type } = await dispatch(createOrUpdateSubscriptionThunk(values));

    if (type === "subscriptions/createOrUpdateSubscriptionThunk/fulfilled") {
      setOpen(false);
    }

    formikHelpers.setSubmitting(false);
  };

  const formValues = useMemo(
    () =>
      selectedSubscriptionId && !isEmpty(specificSubscription)
        ? subscriptionDataFormatterForForm(specificSubscription)
        : null,
    [specificSubscription, selectedSubscriptionId]
  );

  const handleEditSubscription = (row: ISubscription) => {
    dispatch(getSpecificSubscriptionThunk(row.uniqueId));
    setSelectedSubscriptionId(row.uniqueId);
    setOpen(true);
  };

  const handleSubscriptionConfigClick = (row: ISubscription) => {
    navigate(`/dashboard/subscription-manager/${row.uniqueId}/alarms-config`);
  };

  return (
    <Box>
      <Topbar
        theme={theme}
        title={t("drawerMenuLinks.subscriptionManager")}
        primaryButton={{
          id: "add",
          variant: "outlined",
          text: "Add subscription",
          startIcon: <AddIcon />,
          onClick: () => setOpen(true),
        }}
      />
      <Box mx={4}>
        <Table
          columns={getAllSubscriptionsTableColumns(subscriptionTypes)}
          data={allSubscriptions ?? []}
          progressPending={totalSubscriptionsLoading}
          paginationTotalRows={totalSubscriptions}
          searchPlaceholder="Search subscription"
          cellActions={[
            {
              name: "Edit Alarms Config",
              onClick: handleSubscriptionConfigClick,
            },
            { name: "Edit Subscription", onClick: handleEditSubscription },
            {
              name: "Activate subscription",
              when: (row: ISubscription) => row.isDeleted === true,
              onClick: handleActivateSubscription,
            },
            {
              name: "Deactivate subscription",
              when: (row: ISubscription) => row.isDeleted === false,
              onClick: openDeleteConfirmationModal,
            },
          ]}
        />
      </Box>
      <SubscriptionForm
        open={open}
        onClose={() => setOpen(false)}
        theme={theme}
        onSubmit={addSubscriptionHandler}
        initialValues={formValues}
      />
      <DeleteConfirmationModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        title="You are about to delete subscription"
        subTitle="Do you really want to delete this subscription? This process cannot be undone."
        theme={theme}
        primaryBtnProps={{
          onClick: handleDeactivateSubscription,
          loading: isSubmitting,
        }}
      />
    </Box>
  );
};

export default SubscriptionManager;
