import { useRef } from "react";
import { useAppDispatch, useAppSelector } from "./reduxHooks";
import { selectMinimalSearchedAccounts } from "app/data/selectors";
import { searchMinimalAccountsThunk } from "app/features";

const useParentAccountFetcher = () => {
  const dispatch = useAppDispatch();
  const minimalSearchedAccounts = useAppSelector(selectMinimalSearchedAccounts);
  const pendingParentIds = useRef<Set<string>>(new Set());

  return (options: {
    dataSource: Array<{ [key: string]: any }>;
    idField: string;
  }) => {
    // Extract and validate IDs from data source
    const ids = options.dataSource
      ?.map((item) => item[options.idField])
      .filter((id): id is string => Boolean(id));

    // Get unique IDs and check existing/pending
    const uniqueIds = Array.from(new Set(ids));
    const existingIds = new Set(
      minimalSearchedAccounts?.map((account) => account.uniqueId)
    );

    const newIdsToFetch = uniqueIds.filter(
      (id) => !existingIds.has(id) && !pendingParentIds.current.has(id)
    );

    // Dispatch requests for new IDs
    newIdsToFetch.forEach((parentId) => {
      pendingParentIds.current.add(parentId);
      dispatch(
        searchMinimalAccountsThunk({
          itemsPerPage: 10,
          page: 1,
          where: [{ field: "uniqueId", value: parentId, type: 0 }],
        })
      ).finally(() => {
        pendingParentIds.current.delete(parentId);
      });
    });
  };
};

export default useParentAccountFetcher;
