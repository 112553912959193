import { useNavigate, useParams } from "react-router-dom";
import { capitalize } from "lodash";

import { PageLoader, Topbar, types } from "@vilocnv/allsetra-core";
import { useTheme } from "@mui/material";
import AccountTabLayout from "components/common/AccountTabLayout/AccountTabLayout";
import AccountNotificationSection from "components/sections/admin/AccountNotificationSection/AccountNotificationSection";

import { useAppDispatch, useAppSelector } from "hooks";
import { selectActiveAccountState } from "app/data/selectors";
import { Form, Formik, type FormikHelpers } from "formik";
import { useMemo, useState } from "react";
import { accountDetailsFormatterForForm, accountNotificationsValidationSchema } from "app/data/helpers/accountHelpers";
import { handleAccountUpdateEvent } from "app/data/helpers";
import { updateAccountThunk } from "app/features";
interface IAccountNotification {
    uniqueId: string,
    isYearMileageNotificationEnabled: boolean,
    yearMileageThreshold: number | null,
}

const AccountNotification: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams();

  const { activeAccount, activeAccountId, loading } = useAppSelector(selectActiveAccountState);
  const [submitting, setSubmitting] = useState(false);

  const accountWithNotification = activeAccount as types.IAccount & IAccountNotification;

  const initialValues = useMemo<any>(
    () => ({
      uniqueId: accountWithNotification?.uniqueId || "",
      isYearMileageNotificationEnabled: accountWithNotification?.isYearMileageNotificationEnabled || false,
      yearMileageThreshold: accountWithNotification?.yearMileageThreshold || null,
    }),
    [accountWithNotification, activeAccountId]
  );

  const saveChangesHandler = async (
    values: any,
    formikHelpers: FormikHelpers<IAccountNotification>
  ) => {
    setSubmitting(true);
    formikHelpers.setSubmitting(true);

    const formattedActiveAccount = accountDetailsFormatterForForm(activeAccount)
    
    const updateData = { ...formattedActiveAccount, ...values }
    const { type } = await dispatch(
      updateAccountThunk({ accountId: values?.uniqueId, data: updateData })
    );

    if (type === "accounts/updateAccountThunk/fulfilled") {
      handleAccountUpdateEvent(params.id ?? "", formikHelpers, setSubmitting, dispatch)
    } else {
      formikHelpers.setSubmitting(false);
      setSubmitting(false);
    }

    formikHelpers.setSubmitting(false);
  };

  return (
    <Formik
        initialValues={initialValues}
        validationSchema={accountNotificationsValidationSchema}
        onSubmit={saveChangesHandler}
        enableReinitialize
        validateOnMount
    >
      {({ handleSubmit, isSubmitting, isValid, dirty, resetForm }) => (
        <Form>
          <Topbar
            theme={theme}
            title="Notifications"
            breadcrumbTitle={capitalize(activeAccount?.name) ?? "Go Back"}
            breadcrumbRedirectTo={() => navigate("/dashboard/account-manager")}
            primaryButton={{
              id: "save",
              variant: "contained",
              text: "Save Changes",
              loading: isSubmitting || submitting,
              onClick: handleSubmit,
              disabled: !dirty ? isValid : !isValid,
            }}
            secondaryButton={{
              id: "cancel",
              variant: "text",
              text: "Cancel",
              onClick: () => {
                resetForm();
                navigate("/dashboard/account-manager");
              },
            }}
          />
          <AccountTabLayout>
            {loading ? <PageLoader /> : <AccountNotificationSection />}
          </AccountTabLayout>
        </Form>
      )}
    </Formik>
  );
};

export default AccountNotification;