import { FC, useState } from "react";
import {
  ContentSectionLayout,
  FormikToggleField,
  types,
  Button,
} from "@vilocnv/allsetra-core";
import { ToggleWrapper } from "./AlarmConfigurationFormSection.styled";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "hooks";
import { selectActiveAccountState } from "app/data/selectors";
import { useFormikContext } from "formik";
import { useTheme } from "@mui/material";

interface Props {
  activeObject?: types.IObject | null;
  fromAccounts?: boolean;
  showAwayMode?: boolean;
}

const AlarmConfigurationFormSection: FC<Props> = ({
  activeObject,
  fromAccounts = false,
  showAwayMode = false,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const { setFieldValue } = useFormikContext();

  // Global State
  const { activeAccountId } = useAppSelector(selectActiveAccountState);

  const [hasCustomAlarmConfiguration, setCustomAlarmConfiguration] =
    useState<boolean>(activeObject?.hasCustomAlarmConfiguration || false);

  const onAlarmConfigToggle = () => {
    let toggle = !hasCustomAlarmConfiguration;
    setFieldValue("hasCustomAlarmConfiguration", toggle);
    setCustomAlarmConfiguration(toggle);
    if (!toggle)
      setFieldValue("alarmsConfiguration", {
        alarms: [],
      });
  };

  return (
    <ContentSectionLayout
      title="Alarm configuration"
      subTitle="Some text to help user understand what this block is responsible for."
    >
      {showAwayMode && (
        <ToggleWrapper>
          <FormikToggleField label="Away Mode" name="awayModeEnabled" />
        </ToggleWrapper>
      )}

      {fromAccounts ? (
        <Button
          theme={theme}
          variant={"contained"}
          onClick={() =>
            navigate(
              `/dashboard/account-manager/details/${activeAccountId}/alarms-config`
            )
          }
        >
          Customise alarms
        </Button>
      ) : (
        <ToggleWrapper>
          <FormikToggleField
            onChange={onAlarmConfigToggle}
            value={hasCustomAlarmConfiguration}
            label="Custom Alarm Configuration for object"
            name="hasCustomAlarmConfiguration"
          />
        </ToggleWrapper>
      )}

      {hasCustomAlarmConfiguration && !fromAccounts && (
        <Button
          theme={theme}
          variant={"contained"}
          onClick={() =>
            navigate(
              `/dashboard/${activeObject?.uniqueId}/alarms-config`
            )
          }
        >
          Customise alarms
        </Button>
      )}
    </ContentSectionLayout>
  );
};

export default AlarmConfigurationFormSection;
